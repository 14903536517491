import { Link, Outlet } from 'react-router-dom';
import background from '@/assets/bg.jpeg';
import logo from '@/assets/logo.png';
import { ScrollArea } from '@radix-ui/react-scroll-area';

export const Layout = () => {
  return (
    <div
      className="h-screen w-screen flex flex-col overflow-hidden"
      style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
      <main className="grow min-h-0 container flex justify-center mt-12 mb-12 overflow-hidden">
        <ScrollArea className="border border-white rounded-xl text-white backdrop-blur px-4 pb-4 self-start max-h-full overflow-scroll">
          <img src={logo} className="w-[400px] ml-auto mr-auto" />
          <Outlet />
        </ScrollArea>
      </main>
      <footer className="border-t py-2 backdrop-blur bg-white/20 text-green-100 font-bold">
        <div className="container space-x-8 text-center text-xs">
          <Link to="/">Tickets</Link>
          <Link to="/imprint">Imprint</Link>
          <Link to="/privacy">Privacy</Link>
          {/* <Link to="/faq">FAQs</Link> */}
        </div>
      </footer>
    </div>
  );
};
